<svg on:click width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect width="40" height="40" rx="20" fill="white" fill-opacity="0.12" />
	<path d="M30 17L24 23M30 23L24 17L30 23Z" stroke="white" stroke-width="2" stroke-linecap="round" />
	<path
		d="M10 22.959V17.04C10 16.466 10.448 16 11 16H14.586C14.7183 15.9995 14.8492 15.9723 14.9707 15.9199C15.0922 15.8674 15.2019 15.791 15.293 15.695L18.293 12.307C18.923 11.651 20 12.116 20 13.043V26.957C20 27.891 18.91 28.352 18.284 27.683L15.294 24.314C15.2026 24.2153 15.0918 24.1365 14.9687 24.0825C14.8455 24.0285 14.7125 24.0004 14.578 24H11C10.448 24 10 23.534 10 22.959Z"
		stroke="white"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
