<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
	<circle cx="15" cy="15.5" r="10" fill="#EB5757" />
	<path
		d="M19 19.5L15 15.5M15 15.5L19 11.5M15 15.5L11.0001 19.5M15 15.5L12.8787 13.3787L11.0001 11.5"
		stroke="white"
		stroke-width="3"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
