import { get } from "svelte/store";
import { sound } from "./store";

const clickSound = new Audio("/music/Clickable.mp3");
const bigCountdown = new Audio("/music/BigCountDown.wav");
const countdown = new Audio("/music/CountDown.mp3");
const LeaderboardTransition = new Audio("/music/LeaderboardTransition.wav");
const QuizTransition = new Audio("/music/QuizTransition.wav");
const correct = new Audio("/music/CorrectAnswer.mp3");
const wrong = new Audio("/music/WrongAnswer.mp3");
const flip = new Audio("/music/CardFlip.mp3");
const applause = new Audio("/music/CrowdApplause.mp3");
const bgMusic = new Audio("/music/BgMusic.mp3");

bgMusic.loop = true;

export const playSound = type => {
	let value = get(sound);
	switch (type) {
		case "BGMUSIC":
			play(bgMusic, 0.1, value, false);
			break;
		case "BGMUSICSTOP":
			stop(bgMusic);
			break;
		case "CLICK":
			play(clickSound, 0.2, value);
			break;
		case "BIGCOUNTDOWN":
			play(bigCountdown, 0.2, value, false);
			break;
		case "BIGCOUNTDOWNSTOP":
			stop(bigCountdown);
			break;
		case "THREECOUNTDOWN":
			play(countdown, 0.2, value, false);
			break;
		case "THREECOUNTDOWNSTOP":
			stop(countdown);
			break;
		case "CORRECT":
			play(correct, 0.2, value, false);
			break;
		case "WRONG":
			play(wrong, 0.2, value, false);
			break;
		case "FLIP":
			play(flip, 0.2, value);
			break;
		case "LEADERBOARDTRANSITION":
			play(LeaderboardTransition, 0.2, value);
			break;
		case "QUIZTRANSITION":
			play(QuizTransition, 0.2, value);
			break;
		case "APPLAUSE":
			play(applause, 0.75, value);
			break;
		case "APPLAUSESTOP":
			stop(applause);
			break;
		case "MUTE":
			clickSound.volume = 0;
			countdown.volume = 0;
			bigCountdown.volume = 0;
			LeaderboardTransition.volume = 0;
			QuizTransition.volume = 0;
			correct.volume = 0;
			wrong.volume = 0;
			flip.volume = 0;
			applause.volume = 0;
			bgMusic.volume = 0;
			break;
		case "UNMUTE":
			clickSound.volume = 0.2;
			countdown.volume = 0.2;
			bigCountdown.volume = 0.2;
			LeaderboardTransition.volume = 0.2;
			QuizTransition.volume = 0.2;
			correct.volume = 0.2;
			wrong.volume = 0.2;
			flip.volume = 0.2;
			applause.volume = 0.75;
			bgMusic.volume = 0.1;
			break;
		default:
			break;
	}
};

const play = async (sound, volume, value, refresh = true) => {
	try {
		if (refresh) {
			await sound.pause();
			sound.currentTime = 0;
		}
		sound.volume = value ? volume : 0;
		await sound.play();
	} catch (error) {
		console.log("Browser is preventing autoplay of music");
	}
};

const stop = async sound => {
	try {
		await sound.pause();
		sound.currentTime = 0;
	} catch (error) {
		console.log("Browser is preventing autoplay of music");
	}
};