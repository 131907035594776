<svg class="reconnected" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
	<circle cx="17.5" cy="17.5" r="17.5" fill="#EABB43" />
	<path
		d="M15.4827 26.6969C15.4202 26.6969 15.3561 26.6829 15.2967 26.6516C15.1264 26.561 15.0452 26.3641 15.1014 26.1813L17.1092 19.6001H12.9108C12.7561 19.6001 12.617 19.511 12.5499 19.3735C12.4842 19.2344 12.503 19.0704 12.5983 18.9501L20.2186 9.45163C20.3389 9.30319 20.5452 9.25944 20.7139 9.34694C20.8842 9.43444 20.9671 9.62819 20.9155 9.811L19.0421 16.4001H23.0842C23.2374 16.4001 23.378 16.4876 23.4436 16.6266C23.5108 16.7657 23.4921 16.9298 23.3967 17.0501L15.7952 26.5469C15.7171 26.6454 15.6014 26.6969 15.4827 26.6969Z"
		fill="white"
	/>
</svg>

<style>
	.reconnected {
		width: 20px;
		height: 20px;
	}
	@media screen and (max-width: 1000px), screen and (max-height: 670px) {
		.reconnected {
			width: 15px;
			height: 15px;
		}
	}
</style>
