<script>
	export let btnText;
</script>

<button class="btn" disabled={true}>
	Theme: {btnText}
</button>

<style>
	.btn {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 6px 16px;
		background: rgba(255, 255, 255, 0.24);
		border: 2px solid rgba(255, 255, 255, 0.5);
		backdrop-filter: blur(12px);
		font-family: Manrope;
		font-style: normal;
		font-weight: 800;
		font-size: 15.2727px;
		line-height: 21px;
		color: #ffffff;
		border-radius: 98181.8px;
	}
</style>
