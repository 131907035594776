export const params = new URLSearchParams(window.location.search);
export const getParams = function (name) {
	return "" + params.get(name);
};
export const getGameSessionId = function () {
	return `${getParams("roomId")}+${getParams("sessionId")}`;
};

export const defaultColor = "radial-gradient(52.99% 52.99% at 49.44% 47.01%, #BD6FFA 0%, #2D00AE 100%)";

export function changeToThemePage() {
	window?.DIVE_APP?.changeToAppThemePage?.();
}
