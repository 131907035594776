<script>
	export let iconSize;
</script>

<svg
	class="disconnectedSvg"
	style="--iconSize : {iconSize}"
	width="35"
	height="35"
	viewBox="0 0 35 35"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<circle cx="17.5" cy="17.5" r="17.5" fill="#AC312F" />
	<path
		d="M21.19 8.69107C20.542 8.69097 19.8945 8.92658 19.4224 9.39908L17.5832 11.2383L16.9224 10.5775L15.744 11.7558L17.2382 13.25L15.744 14.7441L16.9224 15.9225L18.4166 14.4284L20.5715 16.5833L19.0774 18.0775L20.2557 19.2558L21.7499 17.7617L23.244 19.2558L24.4224 18.0775L23.7616 17.4167L25.6008 15.5775C26.5758 14.6025 26.5758 13.0173 25.6008 12.0423L24.8684 11.3099L26.0891 10.0892L24.9107 8.9108L23.69 10.1315L22.9576 9.39908C22.4859 8.927 21.838 8.69117 21.19 8.69107ZM12.7557 14.7441L11.5774 15.9225L12.2382 16.5833L10.399 18.4225C9.42397 19.3975 9.42397 20.9827 10.399 21.9577L11.1314 22.6901L9.91069 23.9108L11.0891 25.0892L12.3098 23.8685L13.0422 24.6009C13.5139 25.0726 14.1414 25.3333 14.8098 25.3333C15.4773 25.3333 16.1057 25.0734 16.5774 24.6009L18.4166 22.7617L19.0774 23.4225L20.2557 22.2441L12.7557 14.7441Z"
		fill="white"
	/>
</svg>

<style>
	.disconnectedSvg {
		position: absolute;
		right: 0px;
		top: 1px;
		width: var(--iconSize);
		height: var(--iconSize);
	}
</style>
