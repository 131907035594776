<script>
	import EndRoundIcon from "../icons/EndRoundIcon.svelte";
</script>

<button class="btn" on:click>
	<div class="content">
		End round &nbsp; <EndRoundIcon />
	</div>
</button>

<style>
	.btn {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0.75rem;
		background: #ffffff;

		position: absolute;
		top: 80%;
        right: 3%;
		/* cards */
		box-shadow: 3px 3px 34px rgba(78, 78, 78, 0.25);
		backdrop-filter: blur(12px);

		/* Note: backdrop-filter has minimal browser support */
		border-radius: 8px;

		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 19px;
		text-align: right;

		color: rgba(251, 4, 63, 0.75);
	}

	.content {
		display: flex;
	}

	@media screen and (max-height: 800px) and (orientation: landscape) {
		.btn {
			top: 80%;
        	right: 3%;
		}

	}

	@media screen and (max-height: 650px) and (orientation: landscape) {
		.btn {
			top: 80%;
       		right: 3%;
		}

	}

	@media screen and (max-width: 600px) and (orientation: portrait) {
		.btn {
			top: 100%;
		}
		
	}

	@media screen and (max-width: 500px) and (orientation: portrait) {
		.btn {
			top: 100%;
		}
	}
</style>
