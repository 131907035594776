<script>
	import TriviaIconNew from "../icons/TriviaIconNew.svelte";
	import Sound from "../icons/Sound.svelte";
	import SoundOff from "../icons/SoundOff.svelte";
	import LongCategoryButton from "../components/LongCategoryButton.svelte";
	import Dropdown from "../components/Dropdown.svelte";
	import EndRoundButton from "../components/EndRoundButton.svelte";
	import Modal from "../components/Modal.svelte";

	import {
		listenFirebaseKey,
		dbCategoryName,
		dbHost,
		dbDisableTimer,
		dbGameSessionRoundValue,
		dbBgColor,
	} from "../utilities/database";
	import { getParams, defaultColor } from "../utilities/utils";
	import { playSound } from "../utilities/audio";
	import { info } from "../utilities/Notifier";
	import { sound, gameStart } from "../utilities/store";

	let categoryName;
	let isHost;
	let hostId;
	let userId = getParams("userId");
	let isSoundOn = true;
	let hostRoundController = false;
	let soundState;
	let gameStartState;

	gameStart.subscribe(value => {
		gameStartState = value;
	})

	dbGameSessionRoundValue.on("value", snap => {
		if (!snap.exists()) {
			return;
		}

		listenFirebaseKey(dbCategoryName, dbCategoryNameRef => {
			dbCategoryNameRef.on("value", snap => {
				if (!snap.exists()) {
					return;
				}
				categoryName = snap.val();
			});
		});
	});

	dbHost.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		hostId = snap.val();
	});

	function toggleHostRoundController() {
		playSound("CLICK");
		hostRoundController = !hostRoundController;
	}

	function turnSoundOn() {
		isSoundOn = true;
		sound.update(() => true);
		playSound("CLICK");
	}

	function turnSoundOff() {
		isSoundOn = false;
		playSound("CLICK");
		sound.update(() => false);
	}

	function endRound() {
		playSound("CLICK");

		listenFirebaseKey(dbDisableTimer, async dbDisableTimerRef => {
			await dbDisableTimerRef.set(true);

			await dbGameSessionRoundValue.transaction(count => {
				return count + 1;
			});

			await dbBgColor.set(defaultColor);
			gameStart.update(() => false);
			info(`Previous Round was ended.`, `RoundEnd`, 5000);
			playSound("BGMUSIC");
		});
	}

	$: {
		if (hostId !== userId) {
			isHost = false;
		} else {
			isHost = true;
		}
	}

	$: soundState = $sound;

	$: if (soundState === false) {
		playSound("MUTE");
	} else {
		playSound("UNMUTE");
	}
</script>
<div class="headerContainer">
	{#if isSoundOn === true}
		<Sound on:click={turnSoundOff} />
	{:else}
		<SoundOff on:click={turnSoundOn} />
	{/if}

	{#if gameStartState === true}
		<div class="buttonContainer">
			<div class="longButton">
				<LongCategoryButton btnText={categoryName || ""} />
			</div>
			{#if isHost === true}
				<Dropdown on:click={toggleHostRoundController} />
			{/if}
			{#if hostRoundController === true}
			<Modal>
				<div slot="trigger" let:open>
					<EndRoundButton on:click={open} />
				</div>
				<div slot="header">
					<h1>Are you sure?</h1>
				</div>
				<div slot="content">
					This will end the game for all
				</div>

				<div slot="footer" let:store={{close}}>
					<button class="endBtn" on:click={endRound}>Proceed</button>
				</div>

			</Modal>
				
			{/if}
		</div>
	{/if}
</div>

<style>
	.headerContainer {
		display: flex;
		justify-content: space-between;
		position: absolute;
		flex-direction: row;
		width: 95%;
		align-items: center;
		padding: 2%;
	}

	.endBtn {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 0.7rem;
		color: #fff;
		border: none;
		background: #5a42f0;
		border-radius: 1rem;
		padding: 0.6rem 1.5rem;
		margin: 0.5rem -1rem;
		cursor: pointer;
	}

	.buttonContainer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		column-gap: 5pt;
	}

	@media screen and (max-width: 700px) {
		.longButton {
			display: none;
		}
	}
</style>
