<script>
    const defaultTheme = {
        icon: "🤖",
        name: "Default theme",
        description: "Let us pick a theme for you!",
        th_id: "default",
    };

    export let theme=defaultTheme
    export let isHost=false
    export let onSelectTheme= () => {}
    export let isDisabled = {}
</script>

<main>
    <div
       	class="{isHost ? 'themeActivityCard' : 'themeActivityCardDisabled'}"
        on:click="{!isHost ? () => {} : () => onSelectTheme(theme)}"
    >
        <div class="themeActivityCardName">
            <span dangerouslySetInnerHTML={{ __html: theme.icon }} style="padding-right: 2px" />
            {theme.name}
        </div>
        <div class="themeActivityCardDesc">{theme.description}</div>
        <div
            class="{isHost ? 'themeActivityButton' : 'themeActivityButtonDisabled'}"
        >
            {isDisabled[theme.th_id] ? "PICKING..." : "PICK"}
        </div>
    </div>
</main>

<style>
    .themeActivityCard {
	padding: 10%;
	min-height: 250px;
	text-align: center;
	background: #ffffff;
	border-radius: 20px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	flex-direction: column;
}
.themeActivityCardDisabled {
	cursor: auto;
}
.themeActivityCardName {
	color: #060d19;
	font-weight: 700;
	font-size: 20px;
	padding: 5px;
    line-height: 1.5;
}
.themeActivityCardDesc {
	color: #636f84;
	font-weight: 500;
	font-size: 14px;
	padding: 5px;
	flex: 1;
    line-height: 1.5;
}
.themeActivityButton {
	background: #efe8fa;
	backdrop-filter: blur(4px);
	border-radius: 48px;
	min-height: 40px;
	width: 140px;
	color: #6c44a8;
	font-weight: 800;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.themeActivityCard:hover > .themeActivityButton {
	background: #6c44a8;
	color: #fff;
}
.themeActivityCardDisabled:hover > .themeActivityButton {
	background: #635a72;
	color: #fff;
	cursor: not-allowed;
}
</style>