<script>
	export let iconSize;
</script>

<svg
	class="tick"
	style="--iconSize : {iconSize}"
	width="18"
	height="18"
	viewBox="0 0 18 18"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M18 9C18 13.9703 13.9703 18 9 18C4.02975 18 0 13.9703 0 9C0 4.02975 4.02975 0 9 0C13.9703 0 18 4.02975 18 9Z"
		fill="#40BB45"
	/>
	<path
		d="M13.7638 4.76367L7.65727 10.8774L5.13637 8.36367L3.86377 9.63627L7.65907 13.4226L15.0368 6.03627L13.7638 4.76367Z"
		fill="white"
	/>
</svg>

<style>
	.tick {
		position: absolute;
		right: 0px;
		top: 1px;
		width: var(--iconSize);
		height: var(--iconSize);
	}
</style>
