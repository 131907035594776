<script>
	export let user;
	export let picClass;
	export let fakeClass;
	function validUserProfilePicture(str) {
		try {
			new URL(str);
			return true;
		} catch (err) {
			return false;
		}
	}
	let isValidProfilePicture = validUserProfilePicture(user.profilePicture);
	let handleError = () => {
		isValidProfilePicture = false;
	};
</script>

{#if isValidProfilePicture}
	<img class={picClass} src={user.profilePicture} on:error={handleError} alt="profilePicture" />
{:else}
	<div class={fakeClass}>
		{user.userName[0].toUpperCase()}
	</div>
{/if}

<style>
	.profilePicture {
		border-radius: 50%;
		width: 100%;
		height: 100%;
		color: #fff;
		object-fit: cover;
	}
	.fakeProfilePicture {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: rgb(128, 70, 27);
		color: #fff;
		font-family: "Manrope";
		font-size: 2rem;
		font-weight: 700;
	}
	.gameProfilePicture {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		object-fit: cover;
	}
	.gameFakeProfilePicture {
		min-width: 40px;
		min-height: 40px;
		max-width: 40px;
		max-height: 40px;
		color: white;
		font-weight: 700;
		display: flex;
		font-family: "Manrope";
		font-weight: 700;
		font-size: 0.75rem;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: #343e98;
	}
	.halfProfilePicture {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 12px;
		margin-left: 6px;
		object-fit: cover;
	}
	.halfFakeProfilePicture {
		min-width: 40px;
		min-height: 40px;
		max-width: 40px;
		max-height: 40px;
		color: white;
		font-weight: 700;
		display: flex;
		font-family: "Manrope";
		font-weight: 700;
		font-size: 0.75rem;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: #343e98;
		margin-right: 12px;
		margin-left: 6px;
	}

	@media screen and (max-height: 800px) and (orientation: landscape) {
		.halfFakeProfilePicture {
			min-width: 34px;
			min-height: 34px;
			max-width: 34px;
			max-height: 34px;
			margin-left: 0px;
		}

		.gameProfilePicture {
			width: 34px;
			height: 34px;
		}
		.gameFakeProfilePicture {
			min-width: 34px;
			min-height: 34px;
			max-width: 34px;
			max-height: 34px;
		}

		.halfProfilePicture {
			width: 34px;
			height: 34px;
			margin-left: 0px;
		}
	}

	@media screen and (max-height: 650px) and (orientation: landscape) {
		.halfFakeProfilePicture {
			min-width: 32px;
			min-height: 32px;
			max-width: 32px;
			max-height: 32px;
			margin-left: 0px;
		}

		.gameProfilePicture {
			width: 32px;
			height: 32px;
		}
		.gameFakeProfilePicture {
			min-width: 32px;
			min-height: 32px;
			max-width: 32px;
			max-height: 32px;
		}

		.halfProfilePicture {
			width: 32px;
			height: 32px;
			margin-left: 0px;
		}
	}
</style>
