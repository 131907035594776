<script>
	import { onMount } from "svelte";
	import { dbHost, dbTheme } from "../utilities/database";
	import InfiniteScroll from "./InfiniteScroll.svelte";
	import ThemeActivityCard from "./ThemeActivityCard.svelte";
	import { getParams } from "../utilities/utils";

	let nextUrl = `${process.env.SABLE_URL}/v1/api/dapp/uapp/trivia/themes/`;
	let themes = [];
	let data = [];
	let categories = new Set();
	let selectedCategory = "all";
	let filteredThemes = themes;
	let isDisabled = {};
	let userId = getParams("userId");
	let hostId;
	let isHost;
	let isMoreThemesLoading = false;
	let error = false;

	dbHost.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		hostId = snap.val();
		if (hostId === userId) {
			isHost = true;
		} else {
			isHost = false;
		}
	});

	async function fetchData() {
		try {
			const response = await fetch(nextUrl);
			if (!response.ok) {
				throw Error("Api failed");
			}
			data = await response.json();
			nextUrl = data.next;
			isMoreThemesLoading = false;
		} catch (err) {
			console.error(err);
			isMoreThemesLoading = false;
			error = true;
		}
	}

	onMount(() => {
		fetchData();
	});

	$: themes = [...themes, ...(data.results ? data.results : [])];

	$: categories = themes.reduce(function (cats, theme) {
		if (theme.categories.length) {
			theme.categories.forEach(cat => {
				if (!cats.includes(cat)) {
					cats.push(cat);
				}
			});
		}
		return cats;
	}, []);

	$: if (selectedCategory !== "all") {
		filteredThemes = themes.filter(theme => theme.categories.includes(selectedCategory));
	} else {
		filteredThemes = themes;
	}

	function capitalizeFirstLetter(string) {
		if (!string) return "";
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	function snakeCaseToSentenceCase(string) {
		if (!string) return "";

		const spiltString = string.split("_");
		return capitalizeFirstLetter(spiltString.join(" "));
	}

	async function onSelectTheme(theme) {
		const th_id = theme.th_id;
		if (Object.keys(isDisabled).length > 0) return;
		isDisabled = { ...isDisabled, [th_id]: true };

		try {
			// set theme dbTheme
			dbTheme().set(theme);
		} catch (e) {
			console.error(e);
			error = true;
		}
		isDisabled = {};
	}

	function onReloadClick() {
		isMoreThemesLoading = true;
		error = false;
		fetchData();
	}
</script>

<main>
	{#if error || (isMoreThemesLoading && themes.length === 0)}
		<div
			class="themeActivityLoadingContainer"
			style={{
				background: `#794697 url("trivia-bg.svg")`,
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			<div class="themeActivityBox">
				<img class="themeActivityBoxLogo" src={"trivia.svg"} alt="logo of trivia" />
				<div class="themeActivityName">Trivia</div>
				{#if error}
					<div onClick={onReloadClick} class="themeActivityButton">Reload</div>
				{/if}
				{#if isMoreThemesLoading}
					<div class="themeActivityDesc">Loading...</div>
				{/if}
			</div>
		</div>
	{:else}
		<div class="themeActivityContainer" style="background-color: #EAE6F2">
			<div class="themeActivityHeader">
				<img
					class="themeActivityLogo"
					src="trivia.svg"
					alt="logo for two truth one lie"
					height="65px"
					width="65px"
				/>
				<div class="themeActivityHeaderText">
					<div class="themeActivityHeaderTitle">Trivia</div>
					<div class="themeActivityHeaderDesc">Get your thinking caps on.</div>
				</div>
			</div>
			<div class="themeActivityCategories">
				<div
					class={selectedCategory === "all"
						? "themeActivityCategoryChipsSelected"
						: "themeActivityCategoryChip"}
					on:click={() => (selectedCategory = "all")}
				>
					All
				</div>
				{#each categories as category}
					<div
						key={category}
						class={selectedCategory === category
							? "themeActivityCategoryChipsSelected"
							: "themeActivityCategoryChip"}
						on:click={() => (selectedCategory = category)}
					>
						{snakeCaseToSentenceCase(category)}
					</div>
				{/each}
			</div>
			<div class="themeActivityCardsContainer">
				{#if selectedCategory === "all"}
					<ThemeActivityCard {isHost} {onSelectTheme} {isDisabled} />
				{/if}
				{#each filteredThemes as theme}
					<ThemeActivityCard key={theme.th_id} {theme} {isHost} {onSelectTheme} {isDisabled} />
				{/each}
			</div>
			<InfiniteScroll
				hasMore={nextUrl}
				on:loadMore={() => {
					isMoreThemesLoading = true;
					fetchData();
				}}
			/>
			{#if isMoreThemesLoading}
				<div class="moreThemes">
					<h4>Loading...</h4>
				</div>
			{/if}
		</div>
	{/if}
</main>

<style>
	main {
		font-family: "Manrope";
	}

	.themeActivityContainer {
		position: absolute;
		display: flex;
		flex-direction: column;
		top: 0;
		left: 0;
		right: 0;
		overflow: auto;
		bottom: 0;
		padding: 30px 10%;
		z-index: 1;
		color: #222c2e;
	}

	.themeActivityHeader {
		display: flex;
	}

	.themeActivityLogo {
		margin: 0 5px;
	}
	@media only screen and (min-width: 1000px) {
		.themeActivityLogo {
			margin: 0 20px;
		}
	}

	.themeActivityHeaderText {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.themeActivityHeaderTitle {
		font-weight: 700;
		font-size: 18px;
		color: #222c2e;
	}
	@media only screen and (min-width: 1000px) {
		.themeActivityHeaderTitle {
			font-size: 30px;
		}
	}

	.themeActivityHeaderDesc {
		font-weight: 600;
		font-size: 14px;
		color: #5e5e5e;
		margin-top: 5px;
	}

	.themeActivityCategories {
		display: flex;
		margin: 10px;
		min-height: 49px;
		align-items: center;
		overflow: auto;
	}
	.themeActivityCategoryChip {
		border: 1px solid #71608a;
		border-radius: 43px;
		padding: 2px 10px;
		font-weight: 600;
		color: #5e5e5e;
		margin: 0 4px;
		cursor: pointer;
		white-space: nowrap;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.themeActivityCategoryChip:hover {
		background-color: #a491c1;
		color: #fff;
	}
	.themeActivityCardsContainer {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	}

	.themeActivityCategoryChipsSelected {
		border: 1px solid #71608a;
		border-radius: 43px;
		padding: 2px 10px;
		font-weight: 600;
		color: #fff;
		margin: 0 4px;
		cursor: pointer;
		white-space: nowrap;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #71608a;
	}

	.themeActivityCategoryChipsSelected:hover {
		background-color: #71608a;
	}

	.moreThemes {
		position: relative;
		margin: 3% 0;
		text-align: center;
	}

	.themeActivityLoadingContainer {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	.themeActivityBox {
		margin: 0 auto;
		text-align: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.themeActivityBoxLogo {
		max-width: 200px;
	}

	.themeActivityName {
		font-weight: 700;
		font-size: 36px;
		margin-top: 5px;
		margin-bottom: 10px;
		color: #fff;
	}

	.themeActivityDesc {
		padding: 20px;
	}
	.themeActivityButton {
		background: #efe8fa;
		backdrop-filter: blur(4px);
		border-radius: 48px;
		min-height: 40px;
		width: 140px;
		color: #6c44a8;
		font-weight: 800;
		font-size: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
</style>
