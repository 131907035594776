<script>
	export let selectedCategory;
</script>

<svg class="customaIcon" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		class:selected={selectedCategory}
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M20.0001 4C11.1637 4 4 11.1637 4 20.0001C4 28.8365 11.1637 36.0002 20.0001 36.0002C28.8365 36.0002 36.0002 28.8365 36.0002 20.0001C36.0002 11.1637 28.8365 4 20.0001 4ZM21.4546 25.8183C21.4546 26.2041 21.3014 26.574 21.0286 26.8468C20.7558 27.1196 20.3859 27.2728 20.0001 27.2728C19.6143 27.2728 19.2443 27.1196 18.9716 26.8468C18.6988 26.574 18.5455 26.2041 18.5455 25.8183V21.4546H14.1819C13.7961 21.4546 13.4261 21.3014 13.1533 21.0286C12.8806 20.7558 12.7273 20.3859 12.7273 20.0001C12.7273 19.6143 12.8806 19.2443 13.1533 18.9716C13.4261 18.6988 13.7961 18.5455 14.1819 18.5455H18.5455V14.1819C18.5455 13.7961 18.6988 13.4261 18.9716 13.1533C19.2443 12.8806 19.6143 12.7273 20.0001 12.7273C20.3859 12.7273 20.7558 12.8806 21.0286 13.1533C21.3014 13.4261 21.4546 13.7961 21.4546 14.1819V18.5455H25.8183C26.2041 18.5455 26.574 18.6988 26.8468 18.9716C27.1196 19.2443 27.2728 19.6143 27.2728 20.0001C27.2728 20.3859 27.1196 20.7558 26.8468 21.0286C26.574 21.3014 26.2041 21.4546 25.8183 21.4546H21.4546V25.8183Z"
		fill="black"
	/>
</svg>

<style>
	.customaIcon {
		width: 1.5rem;
		height: 1.5rem;
	}
	.selected {
		fill: #fff;
	}
</style>
