import { writable } from "svelte/store";

export const sound = writable(true);
export const gameStart = writable(false);
export const changePageToChooseCategory = writable(0);
export const notification = writable();

export function booleanStore(initial) {
  const isOpen = writable(initial)
  const { set, update } = isOpen
  return {
    isOpen,
    open: () => set(true),
    close: () => set(false),
    toggle: () => update((n) => !n),
  }
}