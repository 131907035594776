<script>
	import { listenFirebaseKey, dbCountDownTimer, dbCategoryName, dbBgColor } from "../utilities/database";

	import TriviaIconNew from "../icons/TriviaIconNew.svelte";
	import LongCategoryButton from "../components/LongCategoryButton.svelte";
	import { defaultColor } from "../utilities/utils";
	import { playSound } from "../utilities/audio";

	let countDownTimer;
	let categoryName;
	let displayCounter = false;
	let backgroundColor = defaultColor;

	/**
	 * fetch the category name for the button text.
	 */
	listenFirebaseKey(dbCategoryName, dbCategoryNameRef => {
		dbCategoryNameRef.once("value", snap => {
			if (!snap.exists()) {
				return;
			}
			categoryName = snap.val();
		});
	});

	/**
	 * fetch bgcolor so as to display timer only when bgcolor changes
	 */
	dbBgColor.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		backgroundColor = snap.val();
	});

	/**
	 * update countdown timer in the component,
	 * logic to play sound based on countdown timer
	 */
	listenFirebaseKey(dbCountDownTimer, dbCountDownTimerRef => {
		dbCountDownTimerRef.on("value", snap => {
			if (!snap.exists()) {
				return;
			}
			countDownTimer = snap.val();

			if (countDownTimer <= 0) {
				playSound("BIGCOUNTDOWNSTOP");
				playSound("QUIZTRANSITION");
			} else if (countDownTimer === 3) {
				playSound("BIGCOUNTDOWN");
			}
		});
	});

	$: {
		if (backgroundColor !== defaultColor) {
			displayCounter = true;
		}
	}
</script>

<div class="timerContainer">
	{#if displayCounter === true}
		<TriviaIconNew />
		<div class="buttonContainer">
			<LongCategoryButton btnText={categoryName} />
		</div>

		<div class="numberContainer">
			<div class="numberValue">
				{countDownTimer || " "}
			</div>
		</div>
	{/if}
</div>

<style>
	.timerContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		padding: 2%;
	}
	.numberContainer {
		margin: 9vh;
		width: 250px;
		height: 250px;
		background: #ffffff;
		box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.25), inset 0px -10px 0px #d1d1d1;
		border-radius: 50%;
	}

	.buttonContainer {
		margin: 6vh;
	}
	.numberValue {
		font-family: Manrope;
		font-style: normal;
		font-weight: 800;
		font-size: 160px;
		line-height: 134.1%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media screen and (max-height: 720px) and (orientation: landscape) {
		.timerContainer {
			padding: 1%;
		}
		.buttonContainer {
			margin: 3vh;
		}

		.numberContainer {
			margin: 4vh;
		}
	}

	@media screen and (min-height: 1000px) and (orientation: portrait) {
		.timerContainer {
			padding: 1%;
		}

		.buttonContainer {
			margin: 6vh;
		}

		.numberContainer {
			margin: 15vh;
		}
	}
</style>
