<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
	<circle cx="15" cy="15.5" r="10" fill="#27AE60" />
	<path
		d="M9.99994 16.125L13.6252 19.5L20.0001 13"
		stroke="white"
		stroke-width="3"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
