<svg class="hostAction" width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M2.5 30C2.5 30 0 30 0 27.5C0 25 2.5 17.5 12.5 17.5C22.5 17.5 25 25 25 27.5C25 30 22.5 30 22.5 30H2.5ZM12.5 15C14.4891 15 16.3968 14.2098 17.8033 12.8033C19.2098 11.3968 20 9.48912 20 7.5C20 5.51088 19.2098 3.60322 17.8033 2.1967C16.3968 0.790177 14.4891 0 12.5 0C10.5109 0 8.60322 0.790177 7.1967 2.1967C5.79018 3.60322 5 5.51088 5 7.5C5 9.48912 5.79018 11.3968 7.1967 12.8033C8.60322 14.2098 10.5109 15 12.5 15Z"
		fill="#fff"
	/>
</svg>

<style>
	.hostAction {
		width: 5%;
		height: auto;
	}
	@media screen and (max-width: 1000px) {
		.hostAction {
			width: 10%;
			height: auto;
		}
	}
	@media screen and (max-width: 900px) {
		.hostAction {
			width: 15%;
			height: auto;
		}
	}
	@media screen and (max-width: 700px) {
		.hostAction {
			width: 20%;
			height: auto;
		}
	}
	@media screen and (max-width: 600px) {
		.hostAction {
			width: 25%;
			height: auto;
		}
	}
	@media screen and (max-width: 450) {
		.hostAction {
			width: 30%;
			height: auto;
		}
	}
</style>
