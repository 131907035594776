<script context="module">
   const modalList = []
</script>
<script>
	import { booleanStore } from '../utilities/store'
    import Alert from "../icons/Alert.svelte";

  const store = booleanStore(false)
  const { isOpen, open, close } = store
  function keydown(e) {
    e.stopPropagation()
    if (e.key === 'Escape') {
      close()
    }
  }
  function transitionend(e) {
    const node = e.target
    node.focus()
  }
  function modalAction(node) {
    const returnFn = []
    // for accessibility
    if (document.body.style.overflow !== 'hidden') {
      const original = document.body.style.overflow
      document.body.style.overflow = 'hidden'
      returnFn.push(() => {
        document.body.style.overflow = original
      })
    }
    node.addEventListener('keydown', keydown)
    node.addEventListener('transitionend', transitionend)
    node.focus()
    modalList.push(node)
    returnFn.push(() => {
      node.removeEventListener('keydown', keydown)
      node.removeEventListener('transitionend', transitionend)
      modalList.pop()
      // Optional chaining to guard against empty array.
      modalList[modalList.length - 1]?.focus()
    })
    return {
      destroy: () => returnFn.forEach((fn) => fn()),
    }
  }
</script>
<style>
  div.modal {
    position: fixed;
    top: 0;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
  div.modal:not(:focus-within) {
    transition: opacity 0.1ms;
    opacity: 0.99;
  }
  div.backdrop {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
  }
  div.content-wrapper {
    z-index: 10;
    max-width: 95vw;
    background: #FFFFFF;
    font-family: Manrope;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2%;
    font-style: normal;
    /* Container */
    box-shadow: 0px 12px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    overflow: hidden;
    padding: 1rem 4rem;
  }

  div.content {
    overflow: auto;
    color: #F5685C;
    font-size: 0.7rem;
    font-weight: 600;
  }

  div.header {
    font-weight: bold;
    font-size: 1.2rem;
    margin: 1rem 0rem;
  }

  div.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
  }

  .CancelBtn {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    color: #6c44a8;
    border: none;
    cursor: pointer;
    background: transparent;
    margin: 0.5rem -1rem;
  }

</style>
<slot name="trigger" {open}>
  <!-- fallback trigger to open the modal -->
  <button on:click={open}>Open</button>
</slot>
{#if $isOpen}
  <div class="modal" use:modalAction tabindex="0">
    <div class="backdrop" on:click={close} />

    <div class="content-wrapper">
        <Alert />
        <div class="header">
            <slot name="header" {store}>
                <h1>Your Modal Heading Goes Here...</h1>
            </slot>
      </div>

      <div class="content">
        <slot name="content" {store} />
      </div>


      <div class="footer">

        <button on:click={close} class="CancelBtn">Cancel</button>
        <slot name="footer" {store}>
            <!-- fallback -->
            <div>
                <h1>Your Modal Footer Goes Here...</h1>
                <button on:click={close}>Close</button>
            </div>
        </slot>

      </div>
      
    </div>

  </div>
{/if}